import '../css/landing.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import * as Sentry from '@sentry/vue'

createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(
            `./Landing/Pages/${name}.vue`,
            import.meta.glob('./Landing/Pages/**/*.vue')
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_FRONT_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
                Sentry.thirdPartyErrorFilterIntegration({
                    filterKeys: ['q5u3l2fjgib5k791bmhbhp6m'],
                    behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
                }),
            ],
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
            tracePropagationTargets: [import.meta.env.VITE_APP_DOMAINS_LANDING, /^\//],
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })

        app.mount(el)
    },
    progress: {
        color: '#4338ca',
    },
})
